import React from 'react'
import Navigation from '../../components/Navigation';
const InSetting = () => {
  return (
    <main className="w-full h-screen flex flex-row relative overflow-hidden ">
    {/* Navigation component on the left */}
    <Navigation />
    {/* Main section for tabs and content */}
    <section className="flex flex-col p-10 ml-20 w-full gap-5 overflow-auto">
    </section>
 </main>
  )
}

export default InSetting
