import React from 'react'
import Navigation from '../../components/Navigation'
import { Doughnut, Radar, PolarArea, Line } from 'react-chartjs-2';
import { Chart, ArcElement, registerables } from 'chart.js';


Chart.register(...registerables);
Chart.register(ArcElement);
const data1 = {
  labels: [
    'Red',
    'Orange',
    'Yellow',
    'Green',
    'Blue',
    'Purple',
    'Grey'
  ],
  datasets: [{
    label: 'My First Dataset',
    data: [65, 59, 80, 81, 56, 55, 40],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ],
    borderWidth: 1
  }]
};

// Generate labels for the last 7 months
const labels = Array.from({ length: 12 }, (_, i) => {
  const date = new Date();
  date.setMonth(date.getMonth() - i);
  // return date.toLocaleString('default', { month: 'short' });

}).reverse(); // Reverse to show the most recent month first

const data2 = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [{
    label: 'My First Dataset',
    data: [965, 59, 80, 81, 56, 55, 40, 10, 90, 140, 240, 440],
    fill: true,
    borderColor: 'rgb(75, 192, 192)',
    tension: 0.1
  }]
}
// Sample data for the Polar Area Chart
const data3 = {
  labels: [
    'Red',
    'Orange',
    'Yellow',
    'Green',
    'Blue',
    'Purple',
    'Grey'
  ],
  datasets: [{
    label: 'My First Dataset',
    data: [65, 59, 80, 81, 56, 55, 40],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ],
    borderWidth: 1
  }]
};

// Sample data for the Radar Chart
const data4 = {
  labels: [
    'Eating',
    'Drinking',
    'Sleeping',
    'Working',
    'Exercising',
    'Playing',
    'Studying'
  ],
  datasets: [{
    label: 'Activity Levels',
    data: [65, 59, 90, 81, 56, 55, 40],
    backgroundColor: 'rgba(75, 192, 192, 0.2)',
    borderColor: 'rgb(75, 192, 192)',
    borderWidth: 2,
    pointBackgroundColor: 'rgb(75, 192, 192)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(75, 192, 192)'
  }]
};


const InDashboard = () => {
  // Get the current year
  const currentYear = new Date().getFullYear();
  // Generate an array of years in reverse order for the last 10 years
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  return (
    <main className="w-full h-screen flex flex-row relative overflow-hidden">
      <Navigation />
      <section className="flex flex-col p-5 md:p-10 ml-20 w-full gap-5 overflow-auto">
        <h1 className="text-4xl text-neutral-900">Dashboard</h1>

        {/* Responsive Grid Layout for Charts */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-full">

          {/* Doughnut Chart */}
          <div className="border-neutral-100/50 shadow-md p-1 bg-neutral-100/20 rounded border">
            <select className="select select-bordered w-full mb-2">
              <option disabled selected>Filter Chart</option>
              {years.map(year => (
                <option key={year}>{year}</option>
              ))}
            </select>

            <div className="relative w-full h-60">
              <Doughnut
                data={data1}
                options={{
                  responsive: true,
                  maintainAspectRatio: false, // Ensure chart doesn't maintain fixed aspect ratio
                  plugins: {
                    legend: {
                      position: 'right', // Set the legend to the right
                    },
                  },
                }}
                className="absolute top-0 left-0 w-full h-full"
              />
            </div>
          </div>
          {/* Polar Area Chart */}
          <div className="border-neutral-100/50 shadow-md p-1 bg-neutral-100/20 rounded border">
            <select className="select select-bordered w-full mb-2">
              <option disabled selected>Filter Chart</option>
              {years.map(year => (
                <option key={year}>{year}</option>
              ))}
            </select>
            <div className="relative w-full h-60"> {/* Adjust height as needed */}
              <PolarArea
                data={data3}
                options={{
                  responsive: true,
                  maintainAspectRatio: false, // Ensure chart doesn't maintain fixed aspect ratio
                  plugins: {
                    legend: {
                      position: 'right', // Set the legend to the right
                    },
                  },
                }}
                className="absolute top-0 left-0 w-full h-full"
              />
            </div>
          </div>
          {/* Radar Chart */}
          <div className="border-neutral-100/50 shadow-md p-1 bg-neutral-100/20 rounded border">
            <select className="select select-bordered w-full mb-2">
              <option disabled selected>Filter Chart</option>
              {years.map(year => (
                <option key={year}>{year}</option>
              ))}
            </select>
            <div className="relative w-full h-60"> {/* Adjust height as needed */}
              <Radar
                data={data4}
                options={{
                  responsive: true,
                  maintainAspectRatio: false, // Ensure chart doesn't maintain fixed aspect ratio
                  plugins: {
                    legend: {
                      position: 'right', // Set the legend to the right
                    },
                  },
                }}
                className="absolute top-0 left-0 w-full h-full"
              />
            </div>
          </div>

        </div>

        {/* Line Chart - Takes full width below */}
        <div className="w-full border-neutral-100/50 shadow-md p-1 bg-neutral-100/20 rounded border">
          <select className="select select-bordered w-full md:w-6/12 lg:w-4/12 mb-2">
            <option disabled selected>Filter Chart</option>
            {years.map(year => (
              <option key={year}>{year}</option>
            ))}
          </select>

          <div className="relative w-full h-80">
            <Line
              data={data2}
              options={{
                responsive: true, // Enables responsiveness
                maintainAspectRatio: false, // Prevents chart from keeping a fixed aspect ratio
                plugins: {
                  legend: {
                    display: true
                  },
                },
              }}
              className="absolute top-0 left-0 w-full h-full"
            />
          </div>
        </div>

      </section>
    </main>


  )
}

export default InDashboard
