import { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation';
import { useGlobalContext } from '../../context/ContextProvider';

function InStudents() {
    const context = useGlobalContext();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        context.https.post('getnstructor_students', {})
            .then((r) => {
                if (r.status) {
                    setStudents(r.data.students);
                } else {
                    setError('Failed to fetch students data');
                }
            })
            .catch((e) => {
                setError('An error occurred while fetching students data');
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const openModal = (student) => {
        setSelectedStudent(student);
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
        setSelectedStudent(null);
    };

    if (loading) return <div className="flex justify-center items-center h-screen">Loading...</div>;
    if (error) return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;

    return (
        <main className="w-full h-screen flex flex-row relative overflow-hidden">
            {/* Navigation component on the left */}
            <Navigation />

            {/* Main section for tabs and content */}
            <section className="flex flex-col p-10 ml-20 w-full gap-5 overflow-auto">
                <h1 className="text-2xl font-semibold text-gray-800 mb-4">Students List</h1>

                <div className="overflow-x-auto bg-white shadow-md rounded-lg">
                    <table className="min-w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                            <tr>
                                <th className="px-6 py-3">#</th>
                                <th className="px-6 py-3">Username</th> 
                                <th className="px-6 py-3">Email</th>
                                <th className="px-6 py-3">Phone</th>
                                <th className="px-6 py-3">Gender</th>
                                <th className="px-6 py-3">Account Type</th>
                                <th className="px-6 py-3">Verified</th>
                                <th className="px-6 py-3">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {students.map((student, index) => (
                                <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                    <td className="px-6 py-4 font-medium text-gray-900">{index + 1}</td>
                                    <td className="px-6 py-4">{student.username}</td> 
                                    <td className="px-6 py-4">{student.email}</td>
                                    <td className="px-6 py-4">{student.phone}</td>
                                    <td className="px-6 py-4">{student.gender}</td>
                                    <td className="px-6 py-4">{student.account_type}</td>
                                    <td className="px-6 py-4">
                                        {student.verify === "true" ? (
                                            <span className="bg-green-100 text-green-600 font-semibold px-3 py-1 rounded-full text-sm">Verified</span>
                                        ) : (
                                            <span className="bg-red-100 text-red-600 font-semibold px-3 py-1 rounded-full text-sm whitespace-nowrap">Not Verified</span>
                                        )}
                                    </td>
                                    <td className="px-6 py-4">
                                        <button
                                            onClick={() => openModal(student)}
                                            className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 transition"
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Modal for viewing student details */}
                {modal && selectedStudent && (
                    <dialog id="student_modal" className="modal" open>
                        <div className="modal-box">
                            <h3 className="font-bold text-lg mb-4">Student Information</h3>
                            <div className="text-sm">
                                <p><strong>Username:</strong> {selectedStudent.username}</p>
                                <p><strong>First Name:</strong> {selectedStudent.first_name}</p>
                                <p><strong>Last Name:</strong> {selectedStudent.last_name}</p>
                                <p><strong>Email:</strong> {selectedStudent.email}</p>
                                <p><strong>Phone:</strong> {selectedStudent.phone}</p>
                                <p><strong>Gender:</strong> {selectedStudent.gender}</p>
                                <p><strong>Account Type:</strong> {selectedStudent.account_type}</p>
                                <p><strong>Verified:</strong> {selectedStudent.verify === "true" ? "Yes" : "No"}</p>
                                <p><strong>Created At:</strong> {selectedStudent.created_at}</p>
                            </div>
                            <div className="modal-action">
                                <button
                                    onClick={closeModal}
                                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </dialog>
                )}
            </section>
        </main>
    );
}

export default InStudents;
