class holder {
    host() {   
        return  process.env.NODE_ENV === "development"
            ? "http://localhost/Projects/sandbox.valoxity.com/"
            : "https://sandbox.valoxity.com/"
    }
    apiPoint() {
        return 'api/endpoint/lms.valoxity.com';
    }
    viewProduactPage() { 
        return  process.env.NODE_ENV === "development"
            ? "http://localhost/lms/products/"
            : "https://lms.valoxity.com/lms/products/"
    }
    viewVideoWatchPage() { 
        // Check if the URL contains "localhost"
        return  process.env.NODE_ENV === "development"
        ? "http://localhost:3000/panel/video/watch/"
        : "https://lms.valoxity.com/panel/video/watch/" 
    }
}
export default holder;