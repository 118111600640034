import React from 'react';

interface LayoutProps {
  title: string;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  return (
    <div>
      <head>
        <title>{title}</title>
      </head>
      <main>{children}</main>
    </div>
  );
};

const Container: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="container mx-auto px-4">{children}</div>;
};

const NotFoundPage: React.FC = () => {
  return (
    <Layout title="404 Not Found">
      <Container>
        <div className="min-h-[100vh] flex items-center justify-center">
          <div className="mt-16 text-center">
            <h1 className="text-4xl lg:text-5xl font-bold lg:tracking-tight">404</h1>
            <p className="text-lg mt-4 text-slate-600">Page not found.</p>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
