// ProtectedRoute.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useGlobalContext } from './ContextProvider';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const context = useGlobalContext()
    const usenavigate= useNavigate()
    const uselocation = useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const url = `${context.holder.host()}${context.holder.apiPoint()}/lmsToken`;

    useEffect(() => {
        async function validateToken() {
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({})
                });

                //  console.log(await response.text());

                const data = await response.json();
 
                setIsAuthenticated(data.status)
                return data;
                // return false;
            } catch (error) {
                console.error('Token validation error:', error);
                setIsAuthenticated(false)
                return false;
            }
        }

        validateToken().then(isValid => {
            // console.log(uselocation.pathname); 
            if (!isValid.status) {
                // Redirect to login if validation fails
                // window.location.href = "";
                // console.log("login 1");
                usenavigate("/signin")

            } else {
                if (uselocation.pathname === "/") {
                    // Redirect to login if the user is at the root path
                    // window.location.href = "/login";
                    // console.log("login 2");
                } 
                // else if (uselocation.pathname == "/signin" || uselocation.pathname == "/login") {

                // } else if (uselocation.pathname.includes("/panel/video/watch/")) {

                // } else if (uselocation.pathname.includes("/buycourse/")) {

                // }  else {
                //     // If the path is not signin or login, check the account type
                //     const userAccountType = isValid.data.jwt.account_type.toLowerCase();
                //     const currentPath = uselocation.pathname.split("/")[1].toLowerCase();

                //     if (userAccountType !== currentPath) {
                //         // If the account type does not match the first part of the pathname, redirect to login
                //         // console.log(userAccountType);
                //         window.location.href = "/login";
                //         // console.log("login 3");
                //         // console.log(uselocation.pathname); 
                //         // console.log("login 3");
                //     }
                // }
            }
        });
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        // Show loading spinner or redirect if not authenticated
        return <div className="jR8x9d nyoS7c fVfPj" >
            <div className="flex justify-center items-center min-h-screen">
                <div className="w-32 h-32">
                    <span className="loading loading-ring loading-lg"></span>
                </div>
            </div>
        </div>;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
