import React, { useEffect, useRef, useState } from 'react'
import Navigation from '../../components/Navigation'
import { PhotoIcon } from '@heroicons/react/24/solid'
import JoditEditor from 'jodit-react';

import { MdDeleteOutline } from "react-icons/md";
import { FaEdit, FaExternalLinkAlt } from "react-icons/fa";
import { FaRegStarHalfStroke, FaStar } from "react-icons/fa6";

import { useNavigate, useParams } from 'react-router-dom'
import { useGlobalContext } from '../../context/ContextProvider';
import { toast } from 'react-toastify';


const CourseManage = ({ onEdite }) => {
    const usenavigate = useNavigate()
    const context = useGlobalContext();
    const [getCourses, setCourses] = useState([]);
    const [getCoursesX, setCoursesX] = useState([]);
    const [getOnChangeSearch, setOnChangeSearch] = useState("");

    useEffect(() => {
        document.title = "Instructor Manage Courses";
        getCourse();
    }, []);

    function getCourse() {
        context.https.post("getnstructor_dashboard", {})
            .then((r) => {
                if (r.status) {
                    const courses = r.data?.courses || []; // Default to an empty array
                    setCourses(courses.sort((a, b) => b.id - a.id));
                    setCoursesX(courses.sort((a, b) => b.id - a.id));
                }
            })
            .catch(error => {
                console.error("Error fetching courses:", error);
            });
    }

    function ActionToEdit(cuid) {
        // Edit logic here
        usenavigate("/instructor/course-publish/" + cuid)
        onEdite()
    }

    function ActionToDelete(id) {
        context.https.post("deletenstructor_dashboard", { cuid: id })
            .then((r) => {
                if (r.status) { getCourse() }
            })
            .catch(error => {
                console.error("Error deleting course:", error);
            });
    }

    useEffect(() => {
        if (getOnChangeSearch === "") { setCourses(getCoursesX); } else {
            setCourses(getCourses.filter((course) =>
                course.title.toLowerCase().includes(getOnChangeSearch.toLowerCase())
            ));
        }
    }, [getOnChangeSearch]);

    // Function to calculate "one day ago"
    const getDayAgo = (dateString) => {
        const givenDate = new Date(dateString);  // Parse the given date
        const currentDate = new Date();  // Get the current date

        // Calculate the difference in time (milliseconds)
        const differenceInTime = currentDate - givenDate;

        // Convert the time difference from milliseconds to days
        const millisecondsInOneDay = 24 * 60 * 60 * 1000;
        const daysAgo = Math.floor(differenceInTime / millisecondsInOneDay);

        return daysAgo + " day ago";
    };
    return (<>
        <div className="overflow-x-auto">
            <div className="border-b items-end justify-between  sm:flex">
                <div className="flex-1 md:mb-0 mb-3">
                    <div className="text-2xl font-semibold md:mb-3"> Courses </div>
                </div>
                <div className="flex items-center mb-4 space-x-3">
                    <div className="bg-white border flex items-center overflow-hidden relative rounded-lg">
                        <i className="pl-4 -mr-2 relative uil-search"></i>
                        <input className="flex-1 max-h-9 p-4 bg-white border-hidden " placeholder="Search" type="text" onChange={(e) => setOnChangeSearch(e.target.value)} />
                    </div>
                </div>
            </div>
            <table className="table-auto w-full border-separate border-spacing-y-3">
                <thead>
                    <tr className="text-left">
                        {/* Thumb Column */}
                        <th className="w-1/6 px-4 py-2">Thumb</th>
                        {/* Details Column */}
                        <th className="w-3/5 px-4 py-2">Details</th>
                        {/* Action Column */}
                        <th className="w-1/6 text-right px-4 py-2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {getCourses?.map((data, key) => (
                        <tr key={key} className="bg-neutral-100 rounded-lg shadow-sm">
                            {/* Thumbnail Column */}
                            <td className="px-4 py-3 align-center">
                                <a
                                    href={context.holder.viewProduactPage() + data?.cuid}
                                    className="block   overflow-hidden rounded-lg shadow-md"
                                >
                                    <img
                                        src={
                                            context.holder.host() +
                                            "usercontent/lms.valoxity.com/course/" +
                                            data?.cuid +
                                            ".png"
                                        }
                                        alt=""
                                        className="w-full h-full object-cover"
                                    />
                                </a>
                            </td>

                            {/* Details Column */}
                            <td className="px-4 py-3 align-top">
                                <div className="flex flex-col space-y-1">
                                    {/* Stars Rating */}
                                    <div className="flex items-center text-yellow-500">
                                        <FaStar /> <FaStar /> <FaStar /> <FaRegStarHalfStroke /> <FaRegStarHalfStroke />
                                    </div>

                                    {/* Course Title */}
                                    <div className="text-lg font-bold text-gray-800">{data?.title}</div>

                                    {/* Course Level and Duration */}
                                    <div className="text-sm text-gray-600">
                                        {data?.level} Lv • {(data?.lessons_hour / 60).toFixed(1)} H
                                    </div>

                                    {/* Course Price */}
                                    <div className="text-sm mt-1 font-semibold text-blue-600">
                                        Price {data?.price === "0" ? "Free" : "$" + data?.price}
                                    </div>

                                    {/* Updated Badge */}
                                    <div className="text-sm mt-1">
                                        Updated {getDayAgo(data?.lastupdate)}
                                    </div>

                                    {/* Enrolled Count */}
                                    <div className="text-xs opacity-50">
                                        Enrolled {data?.course_enrolled}
                                    </div>
                                </div>
                            </td>

                            {/* Action Column */}
                            <td className="px-4 py-3 text-right">
                                <div className="flex justify-end space-x-4">
                                    {/* Delete Icon */}
                                    <button onClick={() => ActionToDelete(data.cuid)} className="text-red-500 hover:text-red-700">
                                        <MdDeleteOutline className="text-2xl" />
                                    </button>

                                    {/* Edit Icon */}
                                    <button onClick={() => ActionToEdit(data.cuid)} className="text-blue-500 hover:text-blue-700">
                                        <FaEdit className="text-2xl" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


        </div>
    </>)
}
const CoursePublish = () => {
    const gGenerateId = () => {
        return 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, () => {
            const randomHex = (Math.random() * 16) | 0;
            return randomHex.toString(16);
        });
    };
    const editor = useRef(null);
    const { cuid } = useParams()
    const context = useGlobalContext()
    const [qus, setqus] = useState("")
    const [ans, setans] = useState("")
    const [faqs, setFaqs] = useState({
        faq: [],
    });
    const [course, setCourse] = useState({
        cuid: cuid === undefined ? gGenerateId() : cuid,
        title: "",
        short_description: "",  // Fix the typo here
        long_description: "",
        category: "",
        level: "",
        language_made_in: "",
        faq: [],
        price: 0,
        discount_price: 0,
        video_provider: "",
        video_url: "",
        thumbnail: "",
        meta_keywords: "",
        meta_description: "",
        lastupdate: "",
        announcement: ""
    });

    function handleOnsubmit(params) {
        params.preventDefault();
        console.log(params.target.course_title.value);


        if (course.category === "") {
            toast.error("Please category.");
            return;
        }
        if (course.level === "") {
            toast.error("Please level.");
            return;
        }
        if (course.language_made_in === "") {
            toast.error("Please language.");
            return;
        }
        context.https.post("addnewcourse", course).then((r) => {
            console.log(r);
            if (r.status) {
                toast.success(r.data.ms)
            }
        }).catch((e) => { })
    }

    const [selectedImage, setSelectedImage] = useState(null);

    const addFaq = () => {
        faqs.faq.push({ qus: qus, ans: ans })
        setFaqs({ ...faqs, faq: faqs.faq })
        setCourse({ ...course, faq: faqs.faq })
    };
    const removeFaq = (index) => {
        setFaqs((prevState) => ({
            ...prevState,
            faq: prevState.faq.filter((_, i) => i !== index)
        }));
    };
    // Handle image selection and preview
    const handleImageChange = (e) => {
        const input = e.target;

        // Ensure an image was selected
        if (!input.files || !input.files[0]) {
            toast.error("Please select an image file.");
            return;
        }

        const file = input.files[0];

        // Check file size (500KB = 500 * 1024 bytes)
        const maxFileSize = 500 * 1024; // 500KB
        if (file.size > maxFileSize) {
            toast.error("File size must be 500KB or less.");
            return;
        }

        // Create an image element to check dimensions
        const img = new Image();
        const url = URL.createObjectURL(file);

        img.onload = function () {
            const width = img.width;
            const height = img.height;

            // Check if the image dimensions are 800x418
            if (width === 800 && height === 418) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64data = reader.result;
                    setSelectedImage(base64data);

                    // Sending the image as a base64 string in a POST request
                    context.https.post(`uploadimage/lms.valoxity.com/course/${course.cuid}`, {
                        imageRowBase64: base64data
                    }).then((r) => {
                        if (r.status) {
                            // Set the image URL after upload success
                            setSelectedImage(`${context.holder.host()}usercontent/lms.valoxity.com/course/${course.cuid}.png`);
                        }
                    }).catch(err => {
                        toast.error("Failed to upload image. Please try again.");
                    });
                };
                reader.readAsDataURL(file); // Convert image to base64
            } else {
                toast.error(`Image dimensions must be 800x418. Uploaded image is ${width}x${height}.`);
            }

            // Free up memory by revoking the object URL
            URL.revokeObjectURL(url);
        };

        // Trigger the image load
        img.src = url;
    };
    // Remove image if user wants to upload a new one
    const removeImage = () => {
        setSelectedImage(null);
    };
    useEffect(() => {
        document.title = "Instructor Create Course";
        if (cuid) {
            context.https.post("getinstructorCourse", { cuid: cuid })
                .then((r) => {
                    if (r.status) {
                        const courseData = r.data.course || {};
                        setFaqs({ faq: JSON.parse(courseData.faq) || [] })
                        setCourse((prev) => ({
                            ...prev,
                            cuid: cuid === undefined ? gGenerateId() : cuid,
                            title: courseData.title || "",
                            category: courseData.category || "",
                            level: courseData.level || "",
                            discount_price: courseData.discount_price || 0,
                            language_made_in: courseData.language_made_in || "",
                            long_description: courseData.long_description || "",
                            meta_description: courseData.meta_description || "",
                            meta_keywords: courseData.meta_keywords || "",
                            price: courseData.price || 0,
                            short_description: courseData.short_description || "",
                            video_provider: courseData.video_provider || "",
                            video_url: courseData.video_url || "",
                            faq: JSON.parse(courseData.faq) || []  // Ensure it's an array
                        }));
                        setSelectedImage(`${context.holder.host()}usercontent/lms.valoxity.com/course/${courseData.cuid}.png`);
                    }
                }).catch((e) => { })
        }
    }, []);

    return (<>
        <form onSubmit={handleOnsubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Course Information</h2>
                    {/* <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-5">
                            <label htmlFor="course_title" className="block text-sm font-medium leading-6 text-gray-900">
                                Course title *
                            </label>
                            <div className="mt-2">
                                <input required value={course.title}
                                    onChange={(e) => setCourse({ ...course, title: e.target.value })}
                                    id="course_title"
                                    name="course_title"
                                    type="text"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-5">
                            <label htmlFor="Shortdescription" className="block text-sm font-medium leading-6 text-gray-900">
                                Short description *
                            </label>
                            <div className="mt-2">
                                <input required value={course.short_description}
                                    onChange={(e) => setCourse({ ...course, short_description: e.target.value })}
                                    id="Shortdescription"
                                    name="Shortdescription"
                                    type="text"
                                    autoComplete="family-name"
                                    className="h-[100px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="Category" className="block text-sm font-medium leading-6 text-gray-900">
                                Category *
                            </label>
                            <div className="mt-2">
                                <select
                                    id="Category"
                                    name="Category"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    value={course.category || "All"}
                                    onChange={(e) => setCourse({ ...course, category: e.target.value })}   >
                                    <option value="All" disabled>All</option>
                                    <optgroup label="Web Design">
                                        <option value="responsive">Responsive </option>
                                        <option value="wordpress">WordPress </option>
                                    </optgroup>
                                    <optgroup label="Graphic Design">
                                        <option value="photoshop">Photoshop</option>
                                        <option value="drawing">Drawing</option>
                                    </optgroup>
                                    <optgroup label="Prototype Design">
                                        <option value="sketchup">SketchUp</option>
                                        <option value="figma">Figma</option>
                                    </optgroup>
                                    <optgroup label="Frontend Development">
                                        <option value="rontenddesign">Frontend design</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="Level" className="block text-sm font-medium leading-6 text-gray-900">
                                Level *
                            </label>
                            <div className="mt-2">
                                <select
                                    id="Level"
                                    name="Level"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    value={course.level || "Level"}
                                    onChange={(e) => setCourse({ ...course, level: e.target.value })}
                                >
                                    <option value="Level" disabled> Level </option>
                                    <option value="beginner">Beginner</option>
                                    <option value="advanced">Advanced</option>
                                    <option value="intermediate">Intermediate</option>
                                </select>
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="Language" className="block text-sm font-medium leading-6 text-gray-900">
                                Language made in *
                            </label>
                            <div className="mt-2">
                                <select
                                    id="Language"
                                    name="Language"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    value={course.language_made_in || "Language"}
                                    onChange={(e) => setCourse({ ...course, language_made_in: e.target.value })} >
                                    <option value="Language" disabled>Language</option>
                                    <option value="english"  >English</option>
                                    <option value="arabic">Arabic</option>
                                    <option value="dutch">Dutch</option>
                                    <option value="french">French</option>
                                    <option value="german">German</option>
                                    <option value="italian">Italian</option>
                                    <option value="russian">Russian</option>
                                    <option value="spanish">Spanish</option>
                                    <option value="turkish">Turkish</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Description</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">

                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-6 sm:mr-2">

                            <label htmlFor="htmlFor" className="block text-sm font-medium leading-6 text-gray-900">
                                Course description *
                            </label>
                            <JoditEditor
                                ref={editor}
                                value={decodeURIComponent(course.long_description)}
                                onChange={newContent => setCourse({ ...course, long_description: encodeURIComponent(newContent) })}
                                onBlur={newContent => setCourse({ ...course, long_description: encodeURIComponent(newContent) })}
                            />
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="Courseprice" className="block text-sm font-medium leading-6 text-gray-900">
                                Course price *
                            </label>
                            <div className="mt-2">
                                <input required
                                    value={course.price}
                                    id="Courseprice"
                                    name="Courseprice"
                                    type="number"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5
                                     text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                      placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                       focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={(e) => setCourse({ ...course, price: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="CourseDiscountedprice" className="block text-sm font-medium leading-6 text-gray-900">
                                Course Discounted price *
                            </label>
                            <div className="mt-2">
                                <input required
                                    value={course.discount_price}
                                    id="CourseDiscountedprice"
                                    name="CourseDiscountedprice"
                                    type="number"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 
                                    text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                                    placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                    focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={(e) => setCourse({ ...course, discount_price: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3 flex">
                            <div className='mr-5 w-[200px]'>
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                    Provider *
                                </label>
                                <div className="mt-2">
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 py-1.5
                                         text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 
                                         focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        value={course.video_provider || "Provider"}
                                        onChange={(e) => setCourse({ ...course, video_provider: e.target.value })}
                                    >
                                        <option value="Provider" disabled>Provider</option>
                                        <option value={'youtube'}>Youtube</option>
                                    </select>
                                </div>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="ReviewVideoUrl" className="block text-sm font-medium leading-6 text-gray-900">
                                    Review Video Url *
                                </label>
                                <div className="mt-2">
                                    <input required value={course.video_url}
                                        onChange={(e) => setCourse({ ...course, video_url: e.target.value })}
                                        id="ReviewVideoUrl"
                                        name="ReviewVideoUrl"
                                        type="text"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                Thumbnail *
                            </label>
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <div className="text-center">
                                    {selectedImage ? (
                                        <div className="relative">
                                            <img src={selectedImage} alt="Thumbnail Preview" className="mx-auto h-full w-full object-cover rounded-md" />
                                            <button onClick={removeImage} className="absolute top-2 right-2 bg-red-600 text-white px-2 py-1 rounded-md">
                                                Remove
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            <PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input
                                                        required value={course.thumbnail}
                                                        id="file-upload"
                                                        name="file-upload"
                                                        type="file"
                                                        accept="image/*"
                                                        className="sr-only hidden"
                                                        onChange={handleImageChange}
                                                    />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Faq</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600"> </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2">
                            <label htmlFor="Question" className="block text-sm font-medium leading-6 text-gray-900">
                                Question *
                            </label>
                            <div className="mt-2">
                                <input
                                    value={qus}
                                    onChange={(e) => setqus(e.target.value)}
                                    placeholder='Question '
                                    id="Question"
                                    name="Question"
                                    type="text"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="Answer" className="block text-sm font-medium leading-6 text-gray-900">
                                Answer *
                            </label>
                            <div className="mt-2">
                                <input
                                    value={ans}
                                    onChange={(e) => setans(e.target.value)}
                                    placeholder='Answer'
                                    id="Answer"
                                    name="Answer"
                                    type="text"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <button
                                onClick={() => addFaq()}
                                type="button"
                                className="mt-8 w-[200px] rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Add F&Q
                            </button>

                        </div>
                        <div className="sm:col-span-full">

                            <div className="overflow-x-auto">
                                <table className="table-auto w-full border border-gray-200">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th className="p-2 text-left"></th>
                                            <th className="p-2 text-left">Question/Answer</th>
                                            <th className="p-2 text-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {faqs.faq.map((data, key) =>
                                            <tr key={key} className="border-t border-gray-200">
                                                <th className="p-2">{key + 1}</th>
                                                <td className="p-2 line-clamp-3">
                                                    {data.qus} <br />
                                                    {data.ans}
                                                </td>
                                                <td className="p-2" onClick={() => removeFaq(key)}>
                                                    <div className="flex justify-end">
                                                        <MdDeleteOutline className="text-2xl cursor-pointer" />
                                                    </div>
                                                </td>
                                            </tr>)}
                                    </tbody>
                                </table>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="border-b border-gray-900/10 pb-12"  >
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Seo</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        Your website's description, used mostly for SEO and search engines, Max of 100 characters is recommended
                    </p>
                    <div className="mt-10 space-y-10">
                        <div className="sm:col-span-5">
                            <label htmlFor="Metakeywords" className="block text-sm font-medium leading-6 text-gray-900">
                                Meta keywords *
                            </label>
                            <div className="mt-2">
                                <input required value={decodeURIComponent(course.meta_keywords)}
                                    onChange={(e) => setCourse({ ...course, meta_keywords: encodeURIComponent(e.target.value) })}
                                    placeholder='javascript, react'
                                    id="Metakeywords"
                                    name="Metakeywords"
                                    type="text"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-5">
                            <label htmlFor="Metadescription" className="block text-sm font-medium leading-6 text-gray-900">
                                Meta description *
                            </label>
                            <div className="mt-2">
                                <input required value={decodeURIComponent(course.meta_description)}
                                    onChange={(e) => setCourse({ ...course, meta_description: encodeURIComponent(e.target.value) })}
                                    id="Metadescription"
                                    name="Metadescription"
                                    type="text"
                                    autoComplete="family-name"
                                    className="h-[100px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                {/* <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button> */}
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Send For Review
                </button>
            </div>
        </form>
    </>)
}
const CourseSection = () => {
    const context = useGlobalContext()
    const [isSuccessFullyUpload, setSuccessFullyUpload] = useState(false);
    const [getCourseList, setCourseList] = useState([]);
    const [getSectionList, setSectionList] = useState([]);
    const [getSectionTitle, setSectionTitle] = useState("");
    const [getCourseId, setCourseId] = useState("");
    function handleOnsubmit(params) {
        params.preventDefault();
        if (getSectionTitle !== "" && getCourseId !== "") {
            context.https.post("addnewsection", {
                section_title: getSectionTitle,
                course_id: getCourseId,
            })
                .then((r) => {
                    if (r.status) {
                        setSectionList(r.courses);
                        getsection(getCourseId);
                        setSectionTitle("")
                        setSuccessFullyUpload(true)
                        setTimeout(() => {
                            setSuccessFullyUpload(false)
                        }, 3000);
                    }
                }).catch((e) => { })
        }
    }
    useEffect(() => {
        document.title = "Instructor Create Section";
        context.https.post("getcourse", {})
            .then((r) => {
                if (r.status) {
                    setCourseList(r.data.courses);
                }
            }).catch((e) => { })
    }, [])
    function getsection(cid) {
        context.https.post("getsection", { cid: cid })
            .then((r) => {
                if (r.status) {
                    setSectionList(r.data.sections);
                }
            }).catch((e) => { })
    }
    function deleteArray(id) {
        context.https.post("deleteSectionArray", { id: id })
            .then((r) => {
                if (r.status) {
                    getsection(getCourseId)
                }
            }).catch((e) => { })
    }
    return (<>
        <form onSubmit={handleOnsubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Course Information</h2>
                    {/* <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-5">
                            <label htmlFor="course_title" className="block text-sm font-medium leading-6 text-gray-900">
                                Course title *
                            </label>
                            <div className="mt-2">
                                <input required
                                    value={getSectionTitle} onChange={(e) => setSectionTitle(e.target.value)}
                                    id="course_title"
                                    name="course_title"
                                    type="text"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-5">
                            <label htmlFor="Level" className="block text-sm font-medium leading-6 text-gray-900">
                                Level *
                            </label>
                            <div className="mt-2">
                                <select
                                    id="Level"
                                    name="Level"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    defaultValue={"Course"}
                                    onChange={(e) => { setCourseId(e.target.value); getsection(e.target.value); }}
                                >
                                    <option value="Course" disabled>Select Course</option>
                                    {getCourseList?.map((data, index) => <option key={index} value={data.id} >{data.title}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-full">
                            <div className=" overflow-x-auto">
                                <table className="table-auto w-full border border-gray-200">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th className="px-4 py-2 border text-left">Section</th>
                                            <th className="px-4 py-2 border text-left">Total Lessons</th>
                                            <th className="px-4 py-2 border text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getSectionList?.map((data, index) => <tr key={index}>
                                            <td>{data.section_title}</td>
                                            <td>{data.lessons.length}</td>
                                            <td className='cursor-pointer flex justify-center' onClick={() => deleteArray(data.id)}>     <MdDeleteOutline className="text-2xl" /></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Send For Review
                </button>
            </div>
        </form>
    </>)
}
const CourseLesson = () => {
    const context = useGlobalContext()
    const [isSuccessFullyUpload, setSuccessFullyUpload] = useState(false);
    const [getCourseList, setCourseList] = useState([]);
    const [getSectionList, setSectionList] = useState([]);
    const [getLessonList, setLessonList] = useState([]);
    const [getLessonTitle, setLessonTitle] = useState("");
    const [getLessonHour, setLessonHour] = useState("");
    const [getLessonVideoUrl, setLessonVideoUrl] = useState("");
    const [getCourseId, setCourseId] = useState("");
    const [getSectionId, setSectionId] = useState("");
    const [getVideoProvider, setVideoProvider] = useState("");
    useEffect(() => {
        document.title = "Instructor Create Lesson";
        context.https.post("getcourse", {})
            .then((r) => {
                if (r.status) {
                    setCourseList(r.data.courses);
                }
            }).catch((e) => { })
    }, [])
    function getsection(cid) {
        context.https.post("getsection", { cid: cid })
            .then((r) => {
                if (r.status) {
                    setSectionList(r.data.sections);
                }
            }).catch((e) => { })
    }
    function getlesson(sectionId) {
        if (getCourseId !== "") {
            context.https.post("getLesson", {
                course_id: getCourseId,
                section_id: sectionId
            })
                .then((r) => {
                    if (r.status) {
                        setLessonList(r.data.lessons);
                    }
                }).catch((e) => { })
        }
    }
    function handleOnsubmit(params) {
        params.preventDefault();
        context.https.post("addlesson",
            {
                lesson_title: getLessonTitle,
                lesson_video_url: getLessonVideoUrl,
                video_provider: getVideoProvider,
                course_id: getCourseId,
                section_id: getSectionId,
                lesson_hour: getLessonHour,
            }
        )
            .then((r) => {
                setLessonTitle("")
                setLessonHour("")
                setLessonVideoUrl("")
                getlesson(getSectionId)
                setSuccessFullyUpload(true)
                setTimeout(() => {
                    setSuccessFullyUpload(false)
                }, 3000);
            }).catch((e) => { })
    }
    function deleteArray(id) {
        context.https.post("deleteLessonArray", { id: id })
            .then((r) => {
                if (r.status) {
                    getlesson(getSectionId)
                }
            }).catch((e) => { })
    }
    return (<>
        <form onSubmit={handleOnsubmit}>
            <div className="space-y-12">
                <div className="border-y border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Course Information</h2>
                    {/* <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2">
                            <label htmlFor="course_title" className="block text-sm font-medium leading-6 text-gray-900">
                                Lesson title *
                            </label>
                            <div className="mt-2">
                                <input required

                                    value={getLessonTitle}
                                    onChange={(e) => setLessonTitle(e.target.value)}
                                    id="course_title"
                                    name="course_title"
                                    type="text"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="course_title" className="block text-sm font-medium leading-6 text-gray-900">
                                Lesson Hour (m) *
                            </label>
                            <div className="mt-2">
                                <input required
                                    value={getLessonHour}
                                    onChange={(e) => setLessonHour(e.target.value)}
                                    id="course_title"
                                    name="course_title"
                                    type="text"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="course_title" className="block text-sm font-medium leading-6 text-gray-900">
                                Lesson Video *
                            </label>
                            <div className="mt-2">
                                <input required
                                    value={getLessonVideoUrl}
                                    onChange={(e) => setLessonVideoUrl(e.target.value)}
                                    id="course_title"
                                    name="course_title"
                                    type="text"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="Courses" className="block text-sm font-medium leading-6 text-gray-900">
                                Courses *
                            </label>
                            <div className="mt-2">
                                <select
                                    id="Courses"
                                    name="Courses"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    defaultValue={'Course Title'}
                                    onChange={(e) => { setCourseId(e.target.value); getsection(e.target.value); }}>
                                    <option value="Course Title" disabled> Select Course Title</option>
                                    {getCourseList?.map((data, index) => <option key={index} value={data.id} >{data.title}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="Section" className="block text-sm font-medium leading-6 text-gray-900">
                                Section *
                            </label>
                            <div className="mt-2">
                                <select
                                    id="Section"
                                    name="Section"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    defaultValue={'Section'}
                                    onChange={(e) => { setSectionId(e.target.value); getlesson(e.target.value); }}>
                                    <option value="Section" disabled>Select Section</option>
                                    {getSectionList?.map((data, index) => <option key={index} value={data.id} >{data.section_title} - {data.lastupdate}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="VideoType" className="block text-sm font-medium leading-6 text-gray-900">
                                Video Type  *
                            </label>
                            <div className="mt-2">
                                <select
                                    id="VideoType "
                                    name="VideoType "
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    onChange={(e) => setVideoProvider(e.target.value)}
                                    defaultValue={'Overview provider'}>
                                    <option value="Overview provider" disabled>Select Overview provider</option>
                                    <option value="youtube">Youtube</option>
                                    <option value="m3u8">Drm (.m3u8)</option>
                                    <option value="html5">Html5</option>
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-full">
                            <div className=" overflow-x-auto">
                                <table className="table-auto w-full border border-gray-200">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th className='px-4 py-2 border text-left'>Title</th>
                                            <th className='px-4 py-2 border text-left'>Provider</th>
                                            <th className='px-4 py-2 border text-left'>Hour (m)</th>
                                            <th className='px-4 py-2 border text-left'>Url</th>
                                            <th >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getLessonList?.map((data, index) => <tr key={index}>
                                            <td>{data.lesson_title}</td>
                                            <td>{data.video_provider}</td>
                                            <td>{data.lesson_hour}</td>
                                            <td>{data.lesson_video_url}</td>
                                            <td className='cursor-pointer flex justify-center' onClick={() => deleteArray(data.id)}>     <MdDeleteOutline className="text-2xl" /></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Send For Review
                </button>
            </div>
        </form>
    </>)
}
const CourseAssets = () => {
    const context = useGlobalContext();
    const [getCoursesList, setCoursesList] = useState([]);
    const [getAssetsList, setAssetsList] = useState([]);
    const [getAssetsTitle, setAssetsTitle] = useState("");
    const [getAssetsLink, setAssetsLink] = useState("");
    const [getCourseId, setCourseId] = useState("");
    const [getLinkType, setLinkType] = useState("");

    useEffect(() => {
        document.title = "Instructor Upload Assets";
        context.https.post("getcourse", {})
            .then((r) => {
                if (r.status) {
                    setCoursesList(r.data.courses);
                }
            }).catch((e) => {
                console.error(e);
                // alert("An error occurred while fetching courses. Please try again.");
            });
    }, []);

    function getAssets(cid) {
        context.https.post("getAssets", { cid })
            .then((r) => {
                if (r.status) {
                    setAssetsList(r.data.assets);
                }
            }).catch((e) => {
                console.error(e);
                // alert("An error occurred while fetching assets. Please try again.");
            });
    }

    function handleOnsubmit(params) {
        params.preventDefault();
        if (getAssetsTitle && getCourseId) {
            const newAsset = {
                assets_title: getAssetsTitle,
                getCourseId,
                assets_link: getAssetsLink,
                assets_link_type: getLinkType
            };

            context.https.post("setAssets", newAsset)
                .then((r) => {
                    if (r.status) {
                        // Optimistically add the asset
                        setAssetsList(prev => [...prev, newAsset]);
                        setAssetsTitle("");
                        setAssetsLink("");
                        setLinkType("");  // Resetting LinkType
                        getAssets(getCourseId); // Refresh assets from server
                    }
                }).catch((e) => {
                    console.error(e);
                    // alert("An error occurred while submitting the asset. Please try again.");
                });
        }
    }

    function deleteArray(id) {
        context.https.post("delete_assets_download", { id })
            .then((r) => {
                if (r.status) {
                    // Optimistically remove the asset from the list
                    setAssetsList(prev => prev.filter(asset => asset.id !== id));
                }
            }).catch((e) => {
                console.error(e);
                // alert("An error occurred while deleting the asset. Please try again.");
            });
    }

    // Form JSX remains the same

    return (<form onSubmit={handleOnsubmit}>
        <div className="space-y-12">
            <div className="border-y border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Course Information</h2>
                {/* <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            Assets title *
                        </label>
                        <div className="mt-2">
                            <input required
                                value={getAssetsTitle}
                                onChange={(e) => setAssetsTitle(e.target.value)}
                                id="title"
                                name="title"
                                type="text"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="link" className="block text-sm font-medium leading-6 text-gray-900">
                            Assets link *
                        </label>
                        <div className="mt-2">
                            <input required
                                value={getAssetsLink}
                                onChange={(e) => setAssetsLink(e.target.value)}
                                id="link"
                                name="link"
                                type="text"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="LinkType" className="block text-sm font-medium leading-6 text-gray-900">
                            Link Type *
                        </label>
                        <div className="mt-2">
                            <select
                                id="LinkType"
                                name="Link ype"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                defaultValue={'Link Type'}
                                onChange={(e) => { setLinkType(e.target.value); }}>
                                <option value="Link Type" disabled>Link Type</option>
                                <option value={"link"} >Link</option>
                                <option value={"drivepdfpreview"} >Drive Pdf View</option>
                            </select>
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="Courses" className="block text-sm font-medium leading-6 text-gray-900">
                            Courses *
                        </label>
                        <div className="mt-2">
                            <select
                                id="Courses"
                                name="Courses"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                defaultValue={"Course Title"}
                                onChange={(e) => { setCourseId(e.target.value); getAssets(e.target.value); }}>
                                <option value="Course Title" disabled>Select Course</option>
                                {getCoursesList?.map((data, index) => <option key={index} value={data.id} >{data.title}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="sm:col-span-full">
                        <div className=" overflow-x-auto">
                            <table className="table-auto w-full border border-gray-200">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className='px-4 py-2 border text-left'>Title</th>
                                        <th className='px-4 py-2 border text-left'>Link</th>
                                        <th className='px-4 py-2 border text-left'>Type</th>
                                        <th className='px-4 py-2 border text-right'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getAssetsList?.map((data, index) => <tr key={index}>
                                        <td>{data.title}</td>
                                        <td>{data.link}</td>
                                        <td>{data.type}</td>
                                        <td className='cursor-pointer flex justify-center' onClick={() => deleteArray(data.id)}>     <MdDeleteOutline className="text-2xl" /></td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Send For Review
            </button>
        </div>
    </form>)
}
const InCourse = () => {
    const usenavigate = useNavigate()
    const { cuid } = useParams()
    const defaultValue = 0;
    const [isselectTab, selectTab] = useState(null)
    const TabsList = [
        {
            title: "Course Manage",
            tab: <CourseManage onEdite={() => selectTab(TabsList[1].tab)} />
        },
        {
            title: "Course Publish",
            tab: <CoursePublish />
        },
        {
            title: "Course Section",
            tab: <CourseSection />
        },
        {
            title: "Course Lesson",
            tab: <CourseLesson />
        },
        {
            title: "Course Assets",
            tab: <CourseAssets />
        }
    ];
    useEffect(() => {
        if (cuid !== undefined) {
            selectTab(TabsList[1].tab)
        }
    }, [])
    return (
        <main className="w-full h-screen flex flex-row relative">
            {/* Navigation component on the left */}
            <Navigation />
            {/* Main section for tabs and content */}
            <section className="flex flex-col p-10 ml-20 w-full gap-5 overflow-hidden">
                {/* Breadcrumbs and tabs */}
                <div className="breadcrumbs text-sm overflow-hidden">
                    <div className='menu menu-horizontal  bg-base-200 lg:rounded-box'>
                        {TabsList.map((data, key) => (
                            <div
                                onClick={() => {
                                    if (cuid !== undefined && key === 1) {
                                        usenavigate("/instructor/course-publish")
                                    }
                                    selectTab(data.tab)
                                }}
                                key={key}
                                className='hover:bg-gray-200/80 p-2 rounded-sm mr-1 cursor-pointer'>
                                {data.title}
                            </div>
                        ))}
                    </div>
                </div>
                {/* Tab content area with overflow auto */}
                <div className='h-full sm:mt-10 md:mt-0 overflow-auto'>
                    {isselectTab === null ? TabsList[defaultValue].tab : isselectTab}
                </div>
            </section>
        </main>

    )
}

export default InCourse
