import { createBrowserRouter } from 'react-router-dom';
// import React from 'react';
// import ProtectedRoute from './ProtectedRoute';
import App from '../App'; // Adjust the import path as needed 
import NotFoundPage from '../NotFoundPage';  
import Signin from '../page/Signin';
import ProtectedRoute from './ProtectedRoute';
import InDashboard from '../page/instructor/InDashboard';
import InCourse from '../page/instructor/InCourse';
import VideoWatch from '../page/VideoWatch';
import InAnnouncements from '../page/instructor/InAnnouncements';
import InCourseRequests from '../page/instructor/InCourseRequests';
import InStudents from '../page/instructor/InStudents';
import InSetting from '../page/instructor/InSetting';
import InRedeemCodes from '../page/instructor/InRedeemCodes';
// Define the routes with type annotations
const Router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute> <App />  </ProtectedRoute>,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: '/signin',
        element: <Signin />,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: '/instructor',
        element: <InDashboard />,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: '/instructor/course-publish',
        element: <InCourse />,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: '/instructor/announcements',
        element: <InAnnouncements />,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: "/instructor/courserequests",
        element: <InCourseRequests />,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: "/instructor/students",
        element: <InStudents />,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: "/instructor/redeemcodes",
        element: <InRedeemCodes />,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: "/instructor/setting",
        element: <InSetting />,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: '/instructor/course-publish/:cuid',
        element: <InCourse />,
        errorElement: <NotFoundPage />,
        children: []
    },
    {
        path: '/notfoundpage',
        element: <NotFoundPage />,
        errorElement: <NotFoundPage />,
    },
    {
        path: '/course/preview/:cuid',
        element: <VideoWatch />,
        errorElement: <NotFoundPage />,
    }
]);

export default Router;

