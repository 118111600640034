// import React, { useEffect } from 'react'; 
// import { useGlobalContext } from './context/ContextProvider';

import Navigation from "./components/Navigation";
import VideoWatch from "./page/VideoWatch";

// const context = useGlobalContext()
// useEffect(() => {
//   context.https.post("lmsToken", {})
//     .then((data) => {
//       // Handle success
//       console.log("Data received:", data);
//     })
//     .catch((error) => {
//       // Handle error
//       console.error("Error occurred:", error);
//     });
// }, [])
import { toast } from 'react-toastify';




function App() {
   return (<>
      <main className="w-full h-screen flex flex-row relative overflow-hidden ">
         {/* Navigation component on the left */}
         <Navigation />
         {/* Main section for tabs and content */}
         <section className="flex flex-col p-10 ml-20 w-full gap-5 overflow-auto">
         </section>
      </main>
   </>)
}



export default App;
