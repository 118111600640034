import { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation';
import { useGlobalContext } from '../../context/ContextProvider';
function generateCode() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // Only uppercase letters and digits
    let code = '';
  
    for (let i = 0; i < 5; i++) {
      if (i > 0) code += '-'; // Add hyphen after the first group
      code += Array.from({ length: 5 }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
    }
  
    return code;
  }
  
function InRedeemCodes() {
    const [codeType, setCodeType] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');
    const [redeemLimit, setRedeemLimit] = useState('10');
    const [isCustomLimit, setIsCustomLimit] = useState(false);
    const [couponDiscount, setCouponDiscount] = useState('');
    const [codeExpiration, setCodeExpiration] = useState('1');

    const [getCourseList, setCourseList] = useState([]);

    const [codes, setCodes] = useState([]); // State to store all codes
    const [filterText, setFilterText] = useState(''); // State to store filter input

    const context = useGlobalContext();
    useEffect(() => {
        document.title = "Instructor Create Section";
        context.https.post("getcourse", {})
            .then((r) => {
                if (r.status) {
                    setCourseList(r.data.courses);
                }
            }).catch((e) => { })
    }, [])
    const handleCodeTypeChange = (e) => {
        setCodeType(e.target.value);
    };

    const handleLimitChange = (e) => { 
        if (e.target.value === 'custom') {
            setIsCustomLimit(true);
        } else {
            setRedeemLimit(e.target.value);
            setIsCustomLimit(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(codeType===""){return;}
        const newCode = {
            title: e.target.title.value,
            type: codeType,
            course: selectedCourse ,
            limit: isCustomLimit ? e.target.customLimit.value : redeemLimit,
            discount: codeType === 'coupon' ? couponDiscount : "0",
            expiration: codeExpiration,
            code:generateCode()
        };
        console.log(newCode);
        
        setCodes([...codes, newCode]); // Add new code to codes state
        // e.target.reset(); // Reset form fields
        // setSelectedCourse('');
        // setCouponDiscount('');
        // setCodeExpiration('');
        // setRedeemLimit('');
        // setIsCustomLimit(false);
    };

    // Filtered codes based on the filter input
    const filteredCodes = codes.filter((code) =>
        code?.code?.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <main className="w-full h-screen flex flex-row relative overflow-hidden">
            <Navigation />
            <section className="flex flex-col p-10 ml-20 w-full gap-5 overflow-auto">
                <h1 className="text-2xl font-bold mb-5">Create Redeem Code</h1>
                <p className='text-sm text-red-500'>ay page ta akhon offline </p>

                <form onSubmit={handleSubmit} className="flex flex-col gap-5">
                    {/* Redeem Code Title */}
                    <div>
                        <label className="block text-lg font-medium">Redeem Code Title</label>
                        <input
                            type="text"
                            name="title" // Added name for form submission
                            placeholder="Enter code title"
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>

                    {/* Type Selection */}
                    <div>
                        <label className="block text-lg font-medium">Code Type</label>
                        <select 
                            className="w-full p-2 border border-gray-300 rounded"
                            defaultValue={'CodeType'}
                            onChange={handleCodeTypeChange}
                        >
                            <option value="CodeType" disabled  >Code Type</option>
                            <option value="redeem">Redeem</option>
                            <option value="coupon">Coupon</option>
                        </select>
                    </div>

                    {/* Conditional Rendering Based on Code Type */}
                    {codeType === 'redeem' ? (
                        <>
                            {/* Course Selection */}
                            <div>
                                <label className="block text-lg font-medium">Select Course</label>
                                <select 
                                    className="w-full p-2 border border-gray-300 rounded"
                                    required
                                    defaultValue={"selectcourse"}
                                    onChange={(e)=>setSelectedCourse(e.target.value)}
                                >
                                    <option value="selectcourse" disabled>Select a course</option>
                                    <option value="all">All Courses</option>
                                    {getCourseList?.map((data, index) => <option key={index} value={data.id} >{data.title}</option>)}
                                </select>
                            </div>

                            {/* Redeem Limit */}
                            <div>
                                <label className="block text-lg font-medium">Redeem Limit</label>
                                <select
                                    value={redeemLimit}
                                    onChange={handleLimitChange}
                                    className="w-full p-2 border border-gray-300 rounded"
                                >
                                    <option value="10">1-10</option>
                                    <option value="1000">100-1000</option>
                                    <option value="custom">Custom</option>
                                </select>
                            </div>

                            {/* Custom Redeem Limit Input */}
                            {isCustomLimit && (
                                <div>
                                    <label className="block text-lg font-medium">Custom Redeem Limit (Day)</label>
                                    <input
                                        type="number"
                                        name="customLimit" // Added name for form submission
                                        placeholder="Enter custom limit"
                                        className="w-full p-2 border border-gray-300 rounded"
                                        required
                                        onChange={(e)=>setRedeemLimit(e.target.value)}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {/* Coupon: Discount */}
                            <div>
                                <label className="block text-lg font-medium">Discount Amount</label>
                                <input
                                    type="number"
                                    placeholder="Enter discount amount"
                                    className="w-full p-2 border border-gray-300 rounded"
                                    value={couponDiscount}
                                    onChange={(e) => setCouponDiscount(e.target.value)}
                                    required
                                />
                            </div>

                            {/* Coupon: Apply to all courses or specific course */}
                            <div>
                                <label className="block text-lg font-medium">Apply to</label>
                                <select
                                    className="w-full p-2 border border-gray-300 rounded"
                                    required
                                    defaultValue={"selectcourse"}
                                    onChange={(e)=>setSelectedCourse(e.target.value)}
                                >
                                    <option value="selectcourse" disabled>Select a course</option>
                                    <option value="all">All Courses</option>
                                    {getCourseList?.map((data, index) => <option key={index} value={data.id} >{data.title}</option>)}
                                </select>
                            </div>
                        </>
                    )}

                    {/* Expiration Time */}
                    <div>
                        <label className="block text-lg font-medium">Expiration Time</label>
                        <select
                            value={codeExpiration}
                            onChange={(e) => setCodeExpiration(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                        >
                            <option value="1">1 Day</option>
                            <option value="7">7 Days</option>
                            <option value="30">30 Days</option>
                            <option value="100">100 Days</option>
                            <option value="lifetime">Lifetime</option>
                        </select>
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
                        >
                            Create Redeem Code
                        </button>
                    </div>
                </form>

                {/* Filter Input */}
                <div className="mt-10">
                    <label className="block text-lg font-medium">Filter Codes</label>
                    <input
                        type="text"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        placeholder="Search by code"
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>

                {/* Codes Table */}
                <table className="mt-5 w-full border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="p-2 border">Title</th>
                            <th className="p-2 border">Type</th>
                            <th className="p-2 border">Course</th>
                            <th className="p-2 border">Limit</th>
                            <th className="p-2 border">Discount</th>
                            <th className="p-2 border">Expiration</th>
                            <th className="p-2 border">Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCodes.map((code, index) => (
                            <tr key={index} className="border-t">
                                <td className="p-2 border">{code.title}</td>
                                <td className="p-2 border">{code.type}</td>
                                <td className="p-2 border">{code.course || 'N/A'}</td>
                                <td className="p-2 border">{code.limit}</td>
                                <td className="p-2 border">{code.discount || 'N/A'}</td>
                                <td className="p-2 border">{code.expiration}</td>
                                <td className="p-2 border text-center">{code.code}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </main>
    );
}

export default InRedeemCodes;
