import React, { useEffect, useRef, useState } from 'react'
import Navigation from '../../components/Navigation'
import { PhotoIcon } from '@heroicons/react/24/solid'
import JoditEditor from 'jodit-react';
import { MdDeleteOutline } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useNavigate, useParams } from 'react-router-dom'
import { useGlobalContext } from '../../context/ContextProvider';
import { toast } from 'react-toastify';
const CourseManage = ({ onEdite }) => {
    const [getCourseList, setCourseList] = useState([]);
    const [getannouncement, setannouncement] = useState([]);
    const [getCid, setCid] = useState([]);
    const editor = useRef(null);
    const context = useGlobalContext()

    function handleOnsubmit(params) {
        params.preventDefault();
        if(getannouncement===""){return;}
        context.https.post("updateAnnouncements", {announcement:getannouncement,id:getCid}).then((r) => {
            console.log(r);
            if (r.status) {
                toast.success(r.data.ms)
            }
        }).catch((e) => { toast.error(e) })
    }
    function getAnnouncements(params) {
        setCid(params)
        context.https.post("getAnnouncements", {id:params}).then((r) => {
            console.log(r);
            if (r.status) {
                // toast.success()
                setannouncement(r.data.courses[0].announcement)
            }
        }).catch((e) => { })
    }
    useEffect(() => {
        document.title = "Instructor Create Section";
        context.https.post("getcourse", {})
            .then((r) => {
                if (r.status) {
                    setCourseList(r.data.courses);
                }
            }).catch((e) => { })
    }, [])

    return (<>
        <form onSubmit={handleOnsubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Course Information</h2>
                    {/* <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2">
                            <label htmlFor="Course" className="block text-sm font-medium leading-6 text-gray-900">
                                Course *
                            </label>
                            <div className="mt-2">
                                <select
                                    id="Course"
                                    name="Course"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                    defaultValue={"Course"}
                                    onChange={(e) => { getAnnouncements(e.target.value); }}
                                > 
                                    <option value="Course" disabled>Select Course</option>
                                    {getCourseList?.map((data, index) => <option key={index} value={data.id} >{data.title}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Description</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">

                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-6 sm:mr-2">

                            <label htmlFor="htmlFor" className="block text-sm font-medium leading-6 text-gray-900">
                                Course description *
                            </label>
                            <JoditEditor
                                ref={editor}
                                value={decodeURIComponent(getannouncement)}
                                onChange={newContent => setannouncement(encodeURIComponent(newContent))}
                                onBlur={newContent => setannouncement(encodeURIComponent(newContent))}
                            />
                        </div>

                    </div>
                </div>

            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                {/* <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button> */}
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Send For Review
                </button>
            </div>
        </form>
    </>)
}
const InAnnouncements = () => {
    const usenavigate = useNavigate()
    const { cuid } = useParams()
    const defaultValue = 0;
    const [isselectTab, selectTab] = useState(null)
    const TabsList = [
        {
            title: "Course Manage",
            tab: <CourseManage onEdite={() => selectTab(TabsList[1].tab)} />
        },
    ];
    useEffect(() => {
        if (cuid !== undefined) {
            selectTab(TabsList[1].tab)
        }
    }, [])
    return (
        <main className="w-full h-screen flex flex-row relative">
            {/* Navigation component on the left */}
            <Navigation />
            {/* Main section for tabs and content */}
            <section className="flex flex-col p-10 ml-20 w-full gap-5 overflow-hidden">
                {/* Breadcrumbs and tabs */}
                {/* Tab content area with overflow auto */}
                <div className='h-full sm:mt-10 md:mt-0 overflow-auto'>
                    {isselectTab === null ? TabsList[defaultValue].tab : isselectTab}
                </div>
            </section>
        </main>

    )
}

export default InAnnouncements
