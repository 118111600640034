import { motion, useAnimationControls, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import NavigationLink from "./NavigationLink";

import { MdOndemandVideo, MdOutlineAccountTree , MdRedeem  } from "react-icons/md";
import { GrAnnounce } from "react-icons/gr";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { IoMdSettings } from "react-icons/io";
import { FaNewspaper } from "react-icons/fa6";
import { RiLogoutBoxRLine } from "react-icons/ri";

import ProjectLink from "./ProjectLink";
import ProjectNavigation from "./ProjectNavigation";
import { useGlobalContext } from "../context/ContextProvider";
import { useNavigate } from "react-router-dom";

const containerVariants = {
  close: {
    width: "5rem",
    transition: {
      type: "spring",
      damping: 15,
      duration: 0.5,
    },
  },
  open: {
    width: "16rem",
    transition: {
      type: "spring",
      damping: 15,
      duration: 0.5,
    },
  },
};

const svgVariants = {
  close: {
    rotate: 360,
  },
  open: {
    rotate: 180,
  },
};

const Navigation = () => {
  const usenavigate = useNavigate()
  const context = useGlobalContext()
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);

  const containerControls = useAnimationControls();
  const svgControls = useAnimationControls();

  useEffect(() => {
    if (isOpen) {
      containerControls.start("open");
      svgControls.start("open");
    } else {
      containerControls.start("close");
      svgControls.start("close");
    }
  }, [isOpen]);

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
    setSelectedProject(null); // Reset the selected project when toggling menu
  };

  const NavigationLinkList = [
    {
      name: "Dashboard",
      logo: <TbBrandGoogleAnalytics className="stroke-inherit min-w-6 w-6 text-2xl" />,
      href: "/instructor",
    },
    {
      name: "Course",
      logo: <MdOndemandVideo className="stroke-inherit min-w-6 w-6 text-2xl" />,
      href: "/instructor/course-publish",
    },
    {
      name: "Announcements",
      logo: <GrAnnounce className="stroke-inherit min-w-6 w-6 text-2xl" />,
      href: "/instructor/announcements",
    },
    {
      name: "Course Requests",
      logo: <FaNewspaper className="stroke-inherit min-w-6 w-6 text-2xl" />,
      href: "/instructor/courserequests",
    },
    {
      name: "Students List",
      logo: <MdOutlineAccountTree className="stroke-inherit min-w-6 w-6 text-2xl" />,
      href: "/instructor/students",
    },
    {
      name: "Redeem Codes",
      logo: <MdRedeem  className="stroke-inherit min-w-6 w-6 text-2xl" />,
      href: "/instructor/redeemcodes",
    },
    {
      name: "Account Setting",
      logo: <IoMdSettings className="stroke-inherit min-w-6 w-6 text-2xl" />,
      href: "/instructor/setting",
    },
  ];

  const ProjectLinkList = [
    <ProjectLink
      name="Virtual Reality"
      nameClass="Blog"
      setSelectedProject={(e) => setSelectedProject(e)}
    >
      <div className="min-w-4 mx-2 border-pink-600 border rounded-full aspect-square bg-pink-700" />
    </ProjectLink>,
    <ProjectLink
      name="Apple Vision Pro"
      nameClass=""
      setSelectedProject={(e) => setSelectedProject(e)}
    >
      <div className="min-w-4 mx-2 border-indigo-600 border rounded-full aspect-square bg-indigo-700" />
    </ProjectLink>,
    <ProjectLink
      name="Porsche"
      nameClass=""
      setSelectedProject={(e) => setSelectedProject(e)}
    >
      <div className="min-w-4 mx-2 border-cyan-600 border rounded-full aspect-square bg-cyan-700" />
    </ProjectLink>,
    <ProjectLink
      name="Secret Project"
      nameClass=""
      setSelectedProject={(e) => setSelectedProject(e)}
    >
      <div className="min-w-4 mx-2 border-yellow-600 border rounded-full aspect-square bg-yellow-700" />
    </ProjectLink>,
  ];
  function SignOutClicked() {
    context.https.post("logoutLms", {})
      .then((r) => {
        if (r.status) {
          usenavigate("/")
        }
      }).catch((e) => {   })
  }

  return (
    <>
      <motion.nav
        variants={containerVariants}
        animate={containerControls}
        initial="close"
        className="bg-neutral-900 flex flex-col justify-between p-5 absolute top-0 left-0 h-full shadow shadow-neutral-600 z-20"
      >
        {/* Top Section */}
        <div className="flex flex-col gap-10">
          {/* Logo and Toggle */}
          <div className="flex flex-row justify-between items-center">
            <div className="w-10 h-10 bg-gradient-to-br from-orange-500 to-amber-700 rounded-full" />
            <button className="p-1 rounded-full flex" onClick={handleOpenClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                className="w-8 h-8 stroke-neutral-200"
              >
                <motion.path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  variants={svgVariants}
                  animate={svgControls}
                  d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  transition={{
                    duration: 0.5,
                    ease: "easeInOut",
                  }}
                />
              </svg>
            </button>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col gap-3">
            {NavigationLinkList.map((data, index) => (
              <div key={index}>
                <NavigationLink name={data.name} href={data.href}>
                  {data.logo}
                </NavigationLink>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3 hidden">
          {ProjectLinkList.map((data, key) => <div key={key}>{data}</div>)}
        </div>
        {/* Bottom Section: SignOut button */}
        <div className="flex flex-col gap-3">
          <div
            className="flex p-1 rounded cursor-pointer stroke-[0.75] hover:stroke-neutral-100 
              stroke-neutral-400 text-neutral-400 hover:text-neutral-100 place-items-center gap-3 
              hover:bg-neutral-700/30 transition-colors duration-100"
            onClick={() => SignOutClicked()}
          >
            <RiLogoutBoxRLine className="stroke-inherit min-w-6 w-6 text-2xl" />
            <p className="text-inherit font-poppins overflow-clip truncate whitespace-nowrap tracking-wide text-base">
              SignOut
            </p>
          </div>
        </div>
      </motion.nav>

      {/* Conditional Project Navigation */}
      <AnimatePresence>
        {selectedProject && (
          <ProjectNavigation
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            isOpen={isOpen}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Navigation;
