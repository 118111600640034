import './VideoWatch.css'
import React, { useEffect, useRef, useState } from 'react';
import Plyr from 'plyr';
import Hls from 'hls.js';
import 'plyr/dist/plyr.css';
import { FaRegPlayCircle } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";





const updateQuality = (newQuality) => {
    if (newQuality === 0) {
        window.hls.currentLevel = -1;
    } else {
        window.hls.levels.forEach((level, levelIndex) => {
            if (level.height === newQuality) {
                window.hls.currentLevel = levelIndex;
            }
        });
    }
};
function SetOverLay(params, playerid) {
    const customOverviewIcon = document.createElement('div');
    customOverviewIcon.classList.add('custom-overview-icon');

    const moving = document.createElement('div');
    moving.classList.add('watermark');
    moving.classList.add('bottom-right');
    moving.innerHTML = 'SUID';
    customOverviewIcon.appendChild(moving);
    params.appendChild(customOverviewIcon);
    // Watermark animation logic
    const watermarkAlgo = [
        "top-left", "top-right",
        "bottom-left", "bottom-right"
    ];
    function getRandomWatermarkAlgo() {
        const randomIndex = Math.floor(Math.random() * watermarkAlgo.length);
        return watermarkAlgo[randomIndex];
    }
    setInterval(() => {
        const randomWatermark = getRandomWatermarkAlgo();
        for (let index = 0; index < moving.classList.length; index++) {
            if (moving.classList[index] !== "watermark") {
                moving.classList.remove(moving.classList[index]);
            }
        }
        moving.innerHTML = "suid";
        moving.classList.add(randomWatermark)

    }, 3000);

    const intervalId = setInterval(() => {
        const videoElement = document.getElementById(playerid);
        if (videoElement) {
            // Access the dynamically added __idm_id__ attribute
            const idmId = videoElement.getAttribute('__idm_id__');
            if (idmId) {
                console.log('__idm_id__ attribute is present:', idmId);
                clearInterval(intervalId);
            } else {
                console.log('__idm_id__ attribute is not present yet');
            }
        }
    }, 1000);
    // Add event listener for resize event
    window.addEventListener('resize', () => {
        var width = window.innerWidth;
        var height = window.innerHeight;
        console.log("detect window resize. width: " + width + " height: " + height);

    });
}
const HlsVideoPlayer = ({ url }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    useEffect(() => {
        let hls;
        const isPlay = false;
        if (Hls.isSupported()) {
            hls = new Hls();
            hls.loadSource(url);
            hls.attachMedia(videoRef.current);

            hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
                const availableQualities = hls.levels.map(level => level.height);
                availableQualities.unshift(0); // Auto quality

                const defaultOptions = {
                    seekTime: 5,
                    loadSprite: true,
                    iconPrefix: 'plyr',
                    iconUrl: 'https://cdn.plyr.io/3.7.8/plyr.svg',
                    controls: ['play-large', 'fast-forward', 'play', 'progress', 'current-time', 'volume', 'settings', 'fullscreen'],
                    settings: ['quality', 'speed', 'loop'],
                    quality: {
                        default: 0, // Auto
                        options: availableQualities,
                        forced: true,
                        onChange: (newQuality) => updateQuality(newQuality, hls),
                    },
                    i18n: {
                        qualityLabel: {
                            0: 'Auto',
                        },
                    },
                };

                playerRef.current = new Plyr(videoRef.current, defaultOptions);
                // Check if the player is currently playing
                playerRef.current.on('play', () => {
                    console.log("Video is now playing.");
                    if (!isPlay) {
                        isPlay = true
                        // Ensure that the overlay is added only after Plyr is fully initialized
                        if (playerRef.current && playerRef.current.elements && playerRef.current.elements.container) {
                            SetOverLay(playerRef.current.elements.container, "video");
                        }
                    }
                });
                playerRef.current.on('pause', () => { console.log("Video is paused."); });
            });

            window.hls = hls; // For debugging purposes
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = url;
            videoRef.current.addEventListener('loadedmetadata', () => {
                playerRef.current = new Plyr(videoRef.current);
                // Check if the player is currently playing
                playerRef.current.on('play', () => {
                    console.log("Video is now playing.");
                    if (!isPlay) {
                        isPlay = true
                        // Ensure that the overlay is added only after Plyr is fully initialized
                        if (playerRef.current && playerRef.current.elements && playerRef.current.elements.container) {
                            SetOverLay(playerRef.current.elements.container, "video");
                        }
                    }
                });
                playerRef.current.on('pause', () => { console.log("Video is paused."); });
            });
        }
        // Clean up Plyr and HLS on component unmount
        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
            if (hls) {
                hls.destroy();
            }
        };
    }, []);

    return (
        <>
            <div
                ref={playerRef}
                className="plyr__video-embed w-full h-full flex items-center justify-center bg-black"
                id="player">
                <video
                    controls
                    ref={videoRef}
                    className="w-[1920px] h-[1080px] max-w-full max-h-full "
                    id="video"
                />
            </div>
        </>
    );
}
const Html5VideoPlayer = ({ url }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [isPlay, setPlay] = useState(false);
    useEffect(() => {
        const player = new Plyr('#player');
        if (videoRef.current) {
            playerRef.current = new Plyr(videoRef.current, {
                seekTime: 5,
                loadSprite: true,
                iconPrefix: 'plyr',
                iconUrl: 'https://cdn.plyr.io/3.7.8/plyr.svg',
                controls: ['play-large', 'fast-forward', 'play', 'progress', 'current-time', 'volume', 'settings', 'fullscreen'],
                settings: ['quality', 'speed', 'loop'],
            });
        }
        // Check if the player is currently playing
        playerRef.current.on('play', () => {
            console.log("Video is now playing.");
            if (!isPlay) {
                setPlay(!isPlay);
                // Ensure that the overlay is added only after Plyr is fully initialized
                if (playerRef.current && playerRef.current.elements && playerRef.current.elements.container) {
                    SetOverLay(playerRef.current.elements.container, "video");
                }
            }
        });
        playerRef.current.on('pause', () => {
            console.log("Video is paused.");
        });
        // Clean up Plyr when the component is unmounted
        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
            if (player) {
                player.destroy();
            }
        };
    }, []);

    return (
        <div className="w-full h-full flex items-center justify-center bg-black"
            ref={playerRef}>
            <video
                id="video"
                ref={videoRef}
                playsInline
                controls
                className="w-[1920px] h-[1080px] max-w-full max-h-full"
            >
                <source
                    src={url}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};
const YoutubeVideoPlayer = ({ url }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [isPlay, setPlay] = useState(false);
    useEffect(() => {
        // Initialize Plyr after the component is mounted 
        const player = new Plyr('#player');
        if (videoRef.current) {
            playerRef.current = new Plyr(videoRef.current, {
                seekTime: 5,
                loadSprite: true,
                iconPrefix: 'plyr',
                iconUrl: 'https://cdn.plyr.io/3.7.8/plyr.svg',
                controls: ['play-large', 'fast-forward', 'play', 'progress', 'current-time', 'volume', 'settings', 'fullscreen'],
                settings: ['quality', 'speed', 'loop'],
                // YouTube plugin
                youtube: {
                    rel: 0,
                    showinfo: 0,
                    iv_load_policy: 3,
                    modestbranding: 1,
                    customControls: true,
                    noCookie: false,
                },
            });
            // Set up play event listener after the player is fully initialized
            player.on('play', () => {
                console.log("Video is now playing.");
                if (!isPlay) {
                    setPlay(!isPlay);
                    // Ensure that the overlay is added only after Plyr is fully initialized 
                    if (player && player.elements && player.elements.container) {
                        SetOverLay(player.elements.container, player.media.id);
                    }
                }
            });
            // Set up pause event listener
            player.on('pause', () => {
                console.log("Video is paused.");
            });
        }
        // Ensure the player instance exists before adding event listeners 
        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
            if (player) {
                player.destroy();
            }
        };
    }, []);
    return (<>
        <div className="plyr__video-embed"
            id="player"
            key={'youtube-video'}
            ref={playerRef}>
            <iframe
                id="video"
                ref={videoRef}
                src={`https://www.youtube.com/embed/${new URLSearchParams(new URL(url).search).get('v')}`}
                allowFullScreen
                allow="autoplay"
            ></iframe>
        </div>
    </>);
}

const Announcements = () => {
    return (<div className="flex justify-center items-center h-full">
        <div className="text-center">
            <h2 className="text-xl font-bold mb-4">No announcements posted yet</h2>
            <p className="w-6/12 mx-auto">
                The instructor hasn’t added any announcements to this course yet. Announcements are used to inform you of updates or additions to the course.
            </p>
        </div>
    </div>
    )
}

// https://docs.google.com/document/d/1yv3SWIfM6IYvev0nknFlVN2WvHkAKthwpycptmERAVQ/preview


const Assets = () => {
    const pdfUrl = "https://drive.google.com/file/d/1tMRpCIJK6kNS7cZ5LDVEM6_coF7GIa5a/preview"; 
    // Additional window resize event
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            console.log(`Window resized to width: ${width}, height: ${height}`);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="flex justify-center h-[100vh]">
            <div className="text-center w-full relative">
                <h2 className="text-xl font-bold mb-4">PDF Viewer</h2>
                <div className="relative w-full h-[90vh]"> 
                    {/* PDF iframe */}
                    <iframe
                        src={pdfUrl}
                        className="w-full h-full border-none"
                        allow="autoplay"
                        title="PDF Viewer"
                    ></iframe>
                    {/* Top right yellow overlay */}
                    <div
                        style={{ background: "rgba(255, 255, 25, 0.37)" }}
                        className="absolute top-0 right-0 h-[100px] w-[80px]"
                    />
                </div>
            </div>
        </div>
    );
};


const VideoWatch = () => {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isScrollableContent, setScrollableContent] = useState(null);

    const menuItems = [
        {
            title: "Course content", className: "lg:hidden", elements: <div className="lg:hidden w-full bg-white h-full overflow-y-auto p-5 select-none">
                <Sidebar
                    setSelectedVideo={setSelectedVideo}
                />
            </div>
        },
        { title: "Overview", className: "", elements: <></> },
        { title: "Q&A", className: "", elements: <></> },
        { title: "Notes", className: "", elements: <></> },
        { title: "Announcements", className: "", elements: <><Announcements /></> },
        { title: "Review", className: "", elements: <></> },
        { title: "Learning tool", className: "", elements: <></> },
        { title: "Assets", className: "", elements: <><Assets /></> },
    ];

    const renderVideoPlayer = () => {
        if (!selectedVideo) return <video className="w-full h-auto bg-gray-500/50" />;
        switch (selectedVideo.video_provider) {
            case "m3u8":
                return <HlsVideoPlayer url={selectedVideo.video_url} />;
            case "html5":
                return <Html5VideoPlayer url={selectedVideo.video_url} />;
            case "youtube":
                return <YoutubeVideoPlayer url={selectedVideo.video_url} />;
            default:
                return <video className="w-full h-auto bg-gray-500/50" />;
        }
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen">
            {/* Main Content Section */}
            <div className="w-full lg:w-3/4 flex flex-col overflow-y-auto ">
                {/* Video Player */}
                <div className="flex-grow">{renderVideoPlayer()}</div>

                {/* Menu Bar */}
                <div className="p-2 flex flex-wrap">
                    {menuItems.map((item, index) => (
                        <button
                            key={index}
                            className={`${item.className} font-bold m-1 p-1`}
                            onClick={() => setScrollableContent(item.elements)}
                        >
                            {item.title}
                        </button>
                    ))}
                </div>

                <hr className="bg-gray-200 h-[1px]" />

                {/* Scrollable Content Section */}
                <div className="h-full p-4">
                    {/* Sidebar for mobile view */}
                    {isScrollableContent === null ? menuItems[0].elements : isScrollableContent}
                </div>
            </div>

            {/* Sidebar for desktop view */}
            <div className="hidden lg:block w-full lg:w-1/4 bg-white shadow-md h-full overflow-y-auto select-none">
                <Sidebar
                    setSelectedVideo={setSelectedVideo}
                />
            </div>
        </div>
    );
};

const Sidebar = ({ setSelectedVideo }) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    const sections = [
        {
            title: 'Section 1: Introduction',
            content: [
                {
                    title: 'Details about youtube .',
                    video_length: "10min",
                    video_provider: "youtube",
                    video_url: "https://www.youtube.com/watch?v=lrSy0n_I4v8"
                },
                {
                    title: 'Details about m3u8 .',
                    video_length: "10min",
                    video_provider: "m3u8",
                    video_url: "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
                },
                {
                    title: 'Details about html5 .',
                    video_length: "10min",
                    video_provider: "html5",
                    video_url: "https://videos.pexels.com/video-files/853772/853772-hd_1920_1080_25fps.mp4"
                }
            ],
        },
    ];

    return (
        <>
            <h2 className="text-xl font-bold lg:p-5 lg:block hidden">Course Content</h2>
            {sections.map((section, index) => (
                <div key={index} className="divide-y divide-dashed cursor-pointer">
                    <div
                        className="flex justify-between items-center bg-[#f7f9fa] p-3 rounded cursor-pointer hover:bg-gray-200 transition"
                        onClick={() => toggleAccordion(index)}
                    >
                        <span>{section.title}</span>
                        <span className="text-gray-600">{activeIndex === index ? <IoIosArrowUp className='text-xl' /> : <IoIosArrowDown className='text-xl' />}</span>
                    </div>
                    <div className={`${activeIndex === index ? '' : 'hidden'} rounded border border-gray-300`}>
                        {
                            section.content.map((data, index) =>
                                <div key={index} className={`p-3 flex `} onClick={() => setSelectedVideo(data)}>
                                    <FaRegPlayCircle className='text-xl mr-1' /><p className='text-sm mr-1'>{data.video_length}</p> <p className='text-sm'>{data.title}</p>
                                </div>)
                        }
                    </div>
                    <hr className="bg-gray-200 h-[1px]" />
                </div>
            ))}
        </>
    );
};


export default VideoWatch
