import { useEffect, useState } from 'react';
import Navigation from '../../components/Navigation';
import { useGlobalContext } from '../../context/ContextProvider';

const InCourseRequests = () => {
    const context = useGlobalContext();
    const [matchedCheckouts, setMatchedCheckouts] = useState([]);
    const [statusFilter, setStatusFilter] = useState('all'); // Default filter

    useEffect(() => {
        document.title = "Instructor Course Request";
        context.https.postCredentials("checkout_history").then(() => {
            if (context.https.data.success) {
                setMatchedCheckouts(context.https.data.matchedCheckouts);
            }
        }).catch((e) => { })
    }, []);

    const ApproveCourse = (id) => {
        context.https.postCredentials("checkout_history_Approvider_notpaid", { id: id, newStatus: 'success' })
            .then(() => {
                if (context.https.data.success) {
                    const updatedCheckouts = matchedCheckouts.map((checkout) => {
                        // If the id matches, update the status
                        if (checkout.id === id) {
                            return { ...checkout, status: "success" };
                        }
                        // Return the original checkout if id doesn't match
                        return checkout;
                    }); 
                    // Set the updated list
                    setMatchedCheckouts(updatedCheckouts);
                }
            }).catch((e) => { })
    };
    const NotPaidCourse = (id) => {
        context.https.postCredentials("checkout_history_Approvider_notpaid", { id: id, newStatus: 'notpaid' })
            .then(() => {
                if (context.https.data.success) {
                    const updatedCheckouts = matchedCheckouts.map((checkout) => {
                        // If the id matches, update the status
                        if (checkout.id === id) {
                            return { ...checkout, status: "notpaid" };
                        }
                        // Return the original checkout if id doesn't match
                        return checkout;
                    });

                    // Set the updated list
                    setMatchedCheckouts(updatedCheckouts);
                }
            }).catch((e) => { })
    };
    const filteredCheckouts = matchedCheckouts.filter((data) => {
        if (statusFilter === 'all') return true;
        return data.status === statusFilter;
    });

    return (
        <main className="w-full h-screen flex flex-row relative overflow-hidden bg-gray-50">
            {/* Navigation component on the left */}
            <Navigation />

            {/* Main section for tabs and content */}
            <section className="flex flex-col p-8 ml-20 w-full gap-6 overflow-auto">
                {/* Section Title */}
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-semibold text-gray-800">Course Checkouts</h1>

                    {/* Filter System */}
                    <div className="flex gap-4">
                        <button
                            className={`px-4 py-2 rounded-md ${statusFilter === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} transition`}
                            onClick={() => setStatusFilter('all')}
                        >
                            All
                        </button>
                        <button
                            className={`px-4 py-2 rounded-md ${statusFilter === 'pending' ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-700'} transition`}
                            onClick={() => setStatusFilter('pending')}
                        >
                            Pending
                        </button>
                        <button
                            className={`px-4 py-2 rounded-md ${statusFilter === 'success' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700'} transition`}
                            onClick={() => setStatusFilter('success')}
                        >
                            Approved
                        </button>
                        <button
                            className={`px-4 py-2 rounded-md ${statusFilter === 'notpaid' ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700'} transition`}
                            onClick={() => setStatusFilter('notpaid')}
                        >
                            Not Paid
                        </button>
                    </div>
                </div>

                {/* Table */}
                <div className="bg-white rounded-lg shadow-md overflow-hidden">
                    <table className="table-auto w-full border-collapse">
                        <thead className="bg-gray-200 text-gray-600 text-sm uppercase font-semibold sticky top-0 z-10">
                            <tr>
                                <th className="w-1/8 py-3 px-4 whitespace-nowrap">No</th>
                                <th className="w-1/8 py-3 px-4 text-left">Date</th>
                                <th className="w-1/8 py-3 px-4 text-left">From</th>
                                <th className="w-1/8 py-3 px-4 text-left">Status</th>
                                <th className="w-1/8 py-3 px-4 text-left">Trxid</th>
                                <th className="w-1/8 py-3 px-4 text-left">Course</th>
                                <th className="w-1/8 py-3 px-4 text-right">Amount</th>
                                <th className="w-1/8 py-3 px-4 text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700 text-sm divide-y divide-gray-200">
                            {filteredCheckouts.length > 0 ? (
                                filteredCheckouts.map((data, index) => (
                                    <tr key={index} className="hover:bg-gray-100">
                                        {/* ID Column */}
                                        <td className="whitespace-nowrap py-4 px-4">{data.id}</td>

                                        {/* Date Column */}
                                        <td className="whitespace-nowrap py-4 px-4 text-left">
                                            {new Date(data.lastupdate).toISOString().split('T')[0]}
                                        </td>

                                        {/* From Column */}
                                        <td className="whitespace-nowrap py-4 px-4">{data.pay_number}</td>

                                        {/* Status Column */}
                                        <td className="whitespace-nowrap py-4 px-4">
                                            {data.status === 'pending' ? (
                                                <span className="bg-yellow-100 text-yellow-600 font-semibold px-3 py-1 rounded-full text-sm">
                                                    Pending
                                                </span>
                                            ) : data.status === 'success' ? (
                                                <span className="bg-green-100 text-green-600 font-semibold px-3 py-1 rounded-full text-sm">
                                                    Approved
                                                </span>
                                            ) : (
                                                <span className="bg-red-100 text-red-600 font-semibold px-3 py-1 rounded-full text-sm">
                                                    Not Paid
                                                </span>
                                            )}
                                        </td>

                                        {/* Trxid Column */}
                                        <td className="whitespace-nowrap py-4 px-4">{data.trxid}</td>

                                        {/* Course Column */}
                                        <td className="whitespace-nowrap py-4 px-4">
                                            <a
                                                href={context.holder.viewProduactPage() + data.cuid}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-500 hover:underline"
                                            >
                                                View Course
                                            </a>
                                        </td>

                                        {/* Amount Column */}
                                        <td className="whitespace-nowrap py-4 px-4 text-right">
                                            ${Number(data?.course?.price) - Number(data?.course?.discount_price)}
                                        </td>

                                        {/* Action Column */}
                                        <td className="whitespace-nowrap py-4 px-4 text-right">
                                            {data.status === 'pending' ? (
                                                <>
                                                    <button
                                                        className="bg-blue-500 text-white px-4 py-1.5 rounded-md hover:bg-blue-600 transition  mr-2"
                                                        onClick={() => ApproveCourse(data.id)}
                                                    >
                                                        Approve
                                                    </button>
                                                    <button
                                                        className="bg-red-500 text-white px-4 py-1.5 rounded-md hover:bg-red-600  transition"
                                                        onClick={() => NotPaidCourse(data.id)}
                                                    >
                                                        Notpaid
                                                    </button>
                                                </>
                                            ) : data.status === 'notpaid' ?
                                                <button
                                                    className="bg-blue-500 text-white px-4 py-1.5 rounded-md hover:bg-blue-600 transition  mr-2"
                                                    onClick={() => ApproveCourse(data.id)}
                                                >
                                                    Approve
                                                </button>
                                                : (
                                                    <span className="text-gray-400">No Action</span>
                                                )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={8} className="text-center py-4 text-gray-500">
                                        No checkouts available.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </section>
        </main>
    );
};

export default InCourseRequests;
